import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "./context/auth/AuthProvider";
import RequireAuth from "./middleware/RequireAuth";
import ManageRoute from "./middleware/ManageRoute";
import Main from "./components/layout/Main";
import SignWithAccessToken from "./pages/auth/SignWithAccessToken";
import SignOutPage from "./pages/auth/SignOutPage";
import ServerError from "./pages/error/500";
import LayoutProvider from "./context/layout/LayoutProvider";
import GroupPage from "./pages/group/GroupPage";
import TemplatePage from "./pages/template/TemplatePage";
import ContactPage from "./pages/ContactPage";
import TemplateAddPage from "./pages/template/TemplateAddPage";
import ModelPage from "./pages/model/ModelPage";
import ModelAddPage from "./pages/model/ModelAddPage";
import CampaignPage from "./pages/campaign/CampaignPage";
import CampaignCreatePage from "./pages/campaign/CampaignCreatePage";
import ModelEditPage from "./pages/model/ModelEditPage";
import GroupContactPage from "./pages/group/GroupContactPage";
import CampaignMailPage from "./pages/campaign/CampaignMailPage";
import RechargePage from "./pages/RechargePage";
import TransactionPage from "./pages/TransactionPage";
import DashboardPage from "./pages/DasboardPage";
import SettingPage from "./pages/SettingPage";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LayoutProvider>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route element={<Main />}>
                <Route path={"dashboard"} element={<DashboardPage />} />
                <Route path={"contacts"} element={<ContactPage />} />
                <Route path={"groups"}>
                  <Route index element={<GroupPage />} />
                  <Route path={"contact/:id"} element={<GroupContactPage />} />
                </Route>
                <Route path={"templates"}>
                  <Route index element={<TemplatePage />} />
                  <Route path={"add"} element={<TemplateAddPage />} />
                </Route>
                <Route path={"models"}>
                  <Route index element={<ModelPage />} />
                  <Route path={"add/:template_id?"} element={<ModelAddPage />} />
                  <Route path={"edit/:id"} element={<ModelEditPage />} />
                </Route>
                <Route path={"campaigns"}>
                  <Route index element={<CampaignPage />} />
                  <Route path={"create"} element={<CampaignCreatePage />} />
                  <Route path={"mails/:id"} element={<CampaignMailPage />} />
                </Route>
                <Route path="recharges" element={<RechargePage />} />
                <Route path="transactions" element={<TransactionPage />} />
                <Route path="settings" element={<SettingPage />} />
              </Route>
              <Route path="logout" element={<SignOutPage />} />
            </Route>
            <Route path="auth/login" element={<SignWithAccessToken />} />
            <Route path="500" element={<ServerError />} />
            <Route path="*" element={<ManageRoute />} />
          </Routes>
        </LayoutProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
