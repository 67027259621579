import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { useEffect } from "react";
import {
  AddButton,
  EditButton,
  LinkButton,
} from "../../components/button/CustomButton";
import CardTable from "../../components/card/CardTable";
import ModalForm from "../../components/modal/ModalForm";
import { HTTPStatusResponse, FormActionType, swal } from "../../config/global";
import { ColumnInterface } from "../../components/table/typings";
import { useQueries } from "../../hooks/useQueries";
import { APP_API_URL } from "../../config/api";
import { useMutateWithInvalidateQueries } from "../../hooks/useMutations";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { InputText, TextArea } from "../../components/form/Input";
import { Select2 } from "../../components/form/Select";
import SimpleButton from "../../components/button/SimpleButton";
import { BiTrash } from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im";
import { Link } from "react-router-dom";

export default function GroupPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    actionForm,
    setActionForm,
    titleForm,
    setTitleForm,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "group", title: "Groupes" });

  /**
   * Form
   */
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  const { data: contacts, refetch: refetchContact } = useQueries(
    APP_API_URL + `contacts?paginate=0`,
    ["contacts-list"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: updateData, isLoading: updateIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "put",
      queryKey
    );

  const { mutateAsync: mutateDeleteData, isLoading: deleteIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "delete",
      queryKey
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      ...values,
      contacts: values.contacts.map((contact: any) => contact.id),
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        toast.success("Groupe ajouté !");
        handleCancelForm();
      }
    });
  };

  const handleSubmitEditForm = (values: any) => {
    updateData(values).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        toast.success("Groupe modifié");
        handleCancelForm();
      }
    });
  };

  const handleSubmitDeleteForm = (element: any) => {
    setCurrentElement(element.id);
    swal
      .fire({
        text: "Confirmer la suppression du group!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          mutateDeleteData({}).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success("Le groupe a été supprimé!");
            }
          });
        }
      });
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
    setCurrentElement(0);
  };

  const handleAddElement = () => {
    setTitleForm("Ajouter un groupe");
    setActionForm(FormActionType.ADD);
    setOpenModal(true);
    reset();
  };

  const handleEditElement = (element: any) => {
    setTitleForm("Modifier un groupe");
    setActionForm(FormActionType.EDIT);
    setValue("name", element.name);
    setValue("description", element.description);
    setCurrentElement(element.id);
    setOpenModal(true);
  };

  /**
   * Effect
   */
  useEffect(() => {
    refetchContact();
  }, [refetchContact]);

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Nombre de Contact",
      key: "nb_contact",
      dataIndex: "nb_contact",
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          {deleteIsLoading && currentElement === element.id ? (
            <ImSpinner2 className="animate-spin text-red-500" size={"24px"} />
          ) : (
            <>
              <Link
                to={`/groups/contact/${element.id}`}
                state={{
                  group: element,
                }}
              >
                <LinkButton className="bg-soft-secondary">Contacts</LinkButton>
              </Link>
              <EditButton
                value={""}
                onClick={() => handleEditElement(element)}
              />
              <SimpleButton
                className="text-red-500 hover:bg-red-500 hover:text-white transition-all rounded"
                title="Supprimer"
                onClick={() => handleSubmitDeleteForm(element)}
              >
                <BiTrash />
              </SimpleButton>
            </>
          )}
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton
            key={"add"}
            onClick={handleAddElement}
            value={"Ajouter"}
          />,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={
          actionForm === FormActionType.ADD
            ? storeDataIsLoading
            : updateIsLoading
        }
        onClose={handleCancelForm}
        onConfirm={handleSubmit(
          actionForm === FormActionType.ADD
            ? handleSubmitAddForm
            : handleSubmitEditForm
        )}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <InputText
              placeholder={"Entrer le nom"}
              label={"Nom"}
              register={register}
              error={errors?.name}
              name={"name"}
            />
          </div>
          <div>
            <TextArea
              label={"Description"}
              placeholder={"Entrer la description"}
              name={"description"}
              error={errors?.description}
              register={register}
            />
          </div>
          {actionForm === FormActionType.ADD && (
            <div>
              <Select2
                multiple
                control={control}
                placeholder={"Selection des contacts"}
                label={"Contacts"}
                register={register}
                error={errors?.contacts}
                name={"contacts"}
                textKeyItem={"email"}
                valueKeyItem={"id"}
                items={contacts?.data?.data}
              />
            </div>
          )}
        </div>
      </ModalForm>
    </>
  );
}
