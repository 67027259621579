import { Suspense, useDeferredValue, useEffect, useRef, useState } from "react";
import EmailEditor, { EditorRef } from "react-email-editor";

import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { Form } from "../../components/form/Form";
import { InputText, Label } from "../../components/form/Input";
import { useForm } from "react-hook-form";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { useMutate } from "../../hooks/useMutations";
import { APP_API_URL } from "../../config/api";
import { SubmitButton } from "../../components/button/CustomButton";
import { toast } from "react-toastify";
import { HTTPStatusResponse } from "../../config/global";
import LoaderBarWrapper from "../../components/loader/LoaderBarWrapper";
import { useNavigate } from "react-router-dom";
import Card from "../../components/card/Card";
import { useQueries } from "../../hooks/useQueries";
import { Select2Tag } from "../../components/form/Select";

export default function TemplatePageAdd() {
  /**
   * Hooks
   */
  const { baseApiUrl } = useHeaderFunctionPage({
    baseApiUrl: "template",
    title: "Contact",
  });
  const emailEditorRef = useRef<EditorRef>(null);
  const { i18n } = useTranslation();
  const [editorIsLoading, setEditorIsLoading] = useDeferredValue(
    useState(true)
  );
  const navigate = useNavigate();

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */

  const { data: tags, refetch: refetchTags } = useQueries(
    APP_API_URL + `tags?paginate=0`,
    ["tags-list"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(
    APP_API_URL + `${baseApiUrl}-store`,
    "post"
  );

  /**
   * Function
   */

  const handleSubmitForm = (values: any) => {
    if (emailEditorRef.current !== null) {
      emailEditorRef.current.editor?.exportHtml((data: any) => {
        const { design, html } = data;
        mutateAsync({
          ...values,
          tag_names: values.tag_names
            .map((tag: any) => tag.name ?? tag.value)
            .toString(),
          content_html: html,
          content_json: JSON.stringify(design),
        }).then((response: any) => {
          if (response?.data?.status === HTTPStatusResponse.OK) {
            toast.success("Le template a été enregistré");
            navigate("/templates");
          }
        });
      });
    } else {
      toast.error("Impossible d'enregistrer le template");
    }
  };

  const onLoadEditor = () => {
    // editor instance is created
    // you can load your template here;
    //emailEditorRef.current?.editor?.loadDesign(templateJson);
  };

  const onReadyEdtior = () => {
    // editor is ready
    //console.log("onReady");
    /* emailEditorRef.current?.editor?.setMergeTags([
      {
        name: "First Name",
        value: "*|FirstName|*",
      },
    ]); */
    setEditorIsLoading(false);
  };

  /**
   * Effect
   */
  useEffect(() => {
    refetchTags();
  }, [refetchTags]);

  /**
   * UI
   * */
  return (
    <div className="relative">
      {editorIsLoading && (
        <div className="text-center w-full h-full absolute flex items-center bg-white bg-opacity-70 z-10">
          <div className="w-full">
            <Loader type="ball-move" className="mx-auto mb-4" />
            <span className="mx-auto">Chargement de l'éditeur...</span>
          </div>
        </div>
      )}
      <LoaderBarWrapper loading={isLoading}>
        <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)}>
          <Card
            title={"Création d'un template"}
            extras={[<SubmitButton className="ml-auto" key={"submit"} />]}
          >
            <div className={"grid grid-cols-3 gap-4 mb-4"}>
              <div>
                <InputText
                  placeholder={"Entrer l'intitulé du template"}
                  label={"Intitulé"}
                  register={register}
                  error={errors?.name}
                  name={"name"}
                />
              </div>
              <div>
                <InputText
                  placeholder={"Entrer la description du template"}
                  label={"Description"}
                  register={register}
                  error={errors?.description}
                  name={"description"}
                />
              </div>
              <div>
                <Select2Tag
                  multiple
                  required={false}
                  control={control}
                  placeholder={"Selectionner les étiquettes"}
                  label={"Etiquettes"}
                  register={register}
                  error={errors?.tag_names}
                  items={tags?.data.data}
                  name={"tag_names"}
                  textKeyItem={"name"}
                  valueKeyItem={"name"}
                />
              </div>
            </div>
            <Label name={""} text={"Contenu"} />
            <Suspense>
              <EmailEditor
                editorId="editor"
                style={{
                  height: "100vh",
                  border: "1px solid lightgray",
                  marginTop: "5px",
                }}
                locale={i18n.language}
                ref={emailEditorRef}
                onLoad={onLoadEditor}
                onReady={onReadyEdtior}
                /* projectId={166928} */
                /* tools={{
                  social: {
                    enabled: true,
                    properties: {
                      icons: "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/twitch-64.png"
                    },
                  },
                }} */
              />
            </Suspense>
          </Card>
        </Form>
      </LoaderBarWrapper>
    </div>
  );
}
