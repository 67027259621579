import { APP_API_URL } from "../config/api";
import { useQueries } from "../hooks/useQueries";
import Card from "../components/card/Card";


export default function DashboardPage() {
  const { data: orgsetting } = useQueries(APP_API_URL + `orgsetting?paginate=0`, [
    "orgsetting",
  ]);
  return (
    <>
      <div className="page-title-box flex justify-between">
        <h4 className="mb-sm-0 font-size-18">SOLDE</h4>
      </div>
      <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-3">
        <Card title="" key={1}>
          <div className="card-body flex justify-between items-center">
          <div className="w-9/12 ml-auto bg-light p-1 my1 text-lg">Crédit Email</div>
            <div className="text-right w-full">
              <div className="">{orgsetting?.data.data.credit}</div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
