import { Suspense, useRef, useState } from "react";
import EmailEditor, { EditorRef } from "react-email-editor";

import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { Form } from "../../components/form/Form";
import { InputText, Label } from "../../components/form/Input";
import { useForm } from "react-hook-form";
import { useMutate } from "../../hooks/useMutations";
import { APP_API_URL } from "../../config/api";
import { SubmitButton } from "../../components/button/CustomButton";
import { toast } from "react-toastify";
import { HTTPStatusResponse } from "../../config/global";
import LoaderBarWrapper from "../../components/loader/LoaderBarWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useQueries } from "../../hooks/useQueries";
import Card from "../../components/card/Card";

export default function ModelPageAdd() {
  /**
   * Hooks
   */
  const emailEditorRef = useRef<EditorRef>(null);
  const { i18n } = useTranslation();
  const [editorIsLoading, setEditorIsLoading] = useState(true);
  const navigate = useNavigate();
  const params = useParams();

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */

  const { data: template } = useQueries(
    APP_API_URL + `template/${params.template_id}`,
    ["template", params?.template_id],
    {
      enabled:
        params.template_id !== undefined && parseInt(params.template_id) > 0,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(
    APP_API_URL + `model-store`,
    "post"
  );

  /**
   * Function
   */

  const handleSubmitForm = (values: any) => {
    if (emailEditorRef.current !== null) {
      emailEditorRef.current.editor?.exportHtml((data: any) => {
        const { design, html } = data;
        mutateAsync({
          ...values,
          content_html: html,
          content_json: JSON.stringify(design),
        }).then((response: any) => {
          if (response?.data?.status === HTTPStatusResponse.OK) {
            toast.success("Le model a été enregistré");
            navigate("/models");
          }
        });
      });
    } else {
      toast.error("Impossible d'enregistrer le model");
    }
  };

  const onLoadEditor = () => {
    // editor instance is created
    // you can load your template here;
    if (template) {
      emailEditorRef.current?.editor?.loadDesign(
        JSON.parse(template.data.data.content_json)
      );
    }
  };

  const onReadyEdtior = () => {
    // editor is ready
    //console.log("onReady");
    setEditorIsLoading(false);
  };
  return (
    <div className="relative">
      {editorIsLoading && (
        <div className="text-center w-full h-full absolute flex items-center bg-white bg-opacity-70">
          <Loader type="ball-move" className="mx-auto" />
        </div>
      )}
      <LoaderBarWrapper loading={isLoading}>
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <Card
            title={"Création d'un model"}
            extras={[<SubmitButton className="ml-auto" key={"submit"} />]}
          >
            <div className={"grid grid-cols-2 gap-4 mb-4"}>
              <div>
                <InputText
                  placeholder={"Entrer l'intitulé du template"}
                  label={"Intitulé"}
                  register={register}
                  error={errors?.name}
                  name={"name"}
                />
              </div>
              <div>
                <InputText
                  placeholder={"Entrer la description du template"}
                  label={"Description"}
                  register={register}
                  error={errors?.description}
                  name={"description"}
                />
              </div>
            </div>
            <Suspense>
              <Label name={""} text={"Contenu"} />
              <EmailEditor
                editorId="editor"
                style={{
                  height: "100vh",
                  border: "1px solid lightgray",
                  marginTop: "5px",
                }}
                locale={i18n.language}
                ref={emailEditorRef}
                onLoad={onLoadEditor}
                onReady={onReadyEdtior}
              />
            </Suspense>
          </Card>
        </Form>
      </LoaderBarWrapper>
    </div>
  );
}
