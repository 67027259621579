import moment from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputNumber } from "../components/form/Input";
import { Select2 } from "../components/form/Select";
import ModalForm from "../components/modal/ModalForm";
import { ColumnInterface } from "../components/table/typings";
import { APP_API_URL } from "../config/api";
import { HTTPStatusResponse, DATE_TIME_FORMAT } from "../config/global";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import { useQueries } from "../hooks/useQueries";

export default function RechargePage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    titleForm,
    setTitleForm,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "recharge", title: "Recharges" });

  /**
   * Form
   */
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  const { data: organizations, refetch: refecthOrganization } = useQueries(
    APP_API_URL + `orgsettings?paginate=0`,
    ["orgsettings-list"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      ...values,
      org_id: values.org_id.id,
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        toast.success("Recharge éffectuée !");
        handleCancelForm();
      }
    });
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Effectuer une recharge");
    setOpenModal(true);
    reset();
  };

  /**
   * Effect
   */
  useEffect(() => {
    refecthOrganization();
  }, [refecthOrganization]);

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Ancien crédit",
      key: "old_credit",
      dataIndex: "old_credit",
    },
    {
      title: "Crédit",
      key: "credit",
      dataIndex: "credit",
    },
    {
      title: "Nouveau crédit",
      key: "new_credit",
      dataIndex: "new_credit",
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format(DATE_TIME_FORMAT)}</>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton
            key={"add"}
            onClick={handleAddElement}
            value={"Ajouter"}
          />,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className="mb-4">
          <Select2
            control={control}
            placeholder={"Selection l'entreprise"}
            label={"Entreprises"}
            register={register}
            error={errors?.org_id}
            name={"org_id"}
            textKeyItem={"name"}
            valueKeyItem={"id"}
            items={organizations?.data?.data}
          />
        </div>
        <div>
          <InputNumber
            label={"Crédit"}
            placeholder={"Entrer le nombre de crédit"}
            name={"credit"}
            error={errors?.credit}
            register={register}
          />
        </div>
      </ModalForm>
    </>
  );
}
