import moment from "moment";
import CardTable from "../../components/card/CardTable";
import { ColumnInterface } from "../../components/table/typings";
import { useQueries } from "../../hooks/useQueries";
import { APP_API_URL } from "../../config/api";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { useLocation } from "react-router-dom";
import { DATE_TIME_FORMAT } from "../../config/global";
import { BadgeCircleCheck, BadgeRounded } from "../../components/badge/Badge";

export default function CampaignMailPage() {
  /**
   * Hooks
   */
  const { state } = useLocation();
  const campaign = state?.campaign;
  const { title, queryKey, pagination, setPagination } = useHeaderFunctionPage({
    baseApiUrl: `mails-${campaign.id}`,
    title: `Détails sur la campagne "${campaign.name}"`,
  });

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `mails/${campaign.id}?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (element: any) => {
        if (element.status === "sent") {
          return (
            <BadgeRounded className="bg-soft-secondary" text={element.status} />
          );
        } else if (
          element.status === "delivered" ||
          element.status === "spam"
        ) {
          return (
            <BadgeRounded className="bg-soft-success" text={element.status} />
          );
        } else {
          return (
            <BadgeRounded className="bg-soft-danger" text={element.status} />
          );
        }
      },
    },
    {
      title: "Mail ouvert",
      key: "sent_at",
      render: (element: any) => (
        <>
          <BadgeCircleCheck active={element.is_open} />
        </>
      ),
    },
    {
      title: "Envoyé à",
      key: "sent_at",
      render: (element: any) => (
        <>{moment(element.sent_at).format(DATE_TIME_FORMAT)}</>
      ),
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format(DATE_TIME_FORMAT)}</>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
    </>
  );
}
