import { Suspense, useRef, useState } from "react";
import EmailEditor, { EditorRef } from "react-email-editor";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { Form } from "../../components/form/Form";
import { InputText, Label } from "../../components/form/Input";
import { useForm } from "react-hook-form";
import { useMutate } from "../../hooks/useMutations";
import { APP_API_URL } from "../../config/api";
import { SubmitButton } from "../../components/button/CustomButton";
import { toast } from "react-toastify";
import { HTTPStatusResponse } from "../../config/global";
import LoaderBarWrapper from "../../components/loader/LoaderBarWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../../components/card/Card";
import { useQueries } from "../../hooks/useQueries";
import { Select2 } from "../../components/form/Select";

export default function ModelEditPage() {
  /**
   * Hooks
   */
  const emailEditorRef = useRef<EditorRef>(null);
  const { i18n } = useTranslation();
  const [editorIsLoading, setEditorIsLoading] = useState(true);
  const navigate = useNavigate();
  const { state } = useLocation();
  const model = state?.model;

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */

  const { data: mergetags } = useQueries(APP_API_URL + "mergetags", [
    "merge-tags",
  ]);

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(
    APP_API_URL + `model/${model?.id}`,
    "put"
  );

  /**
   * Function
   */

  const handleSubmitForm = (values: any) => {
    if (emailEditorRef.current !== null) {
      emailEditorRef.current.editor?.exportHtml((data: any) => {
        const { design, html } = data;
        mutateAsync({
          ...values,
          content_html: html,
          content_json: JSON.stringify(design),
        }).then((response: any) => {
          if (response?.data?.status === HTTPStatusResponse.OK) {
            toast.success("Le model a été mise à jour");
            navigate("/models");
          }
        });
      });
    } else {
      toast.error("Impossible d'enregistrer le model");
    }
  };

  const onLoadEditor = () => {
    // editor instance is created
    // you can load your template here;
    if (!isLoading) {
      emailEditorRef.current?.editor?.loadDesign(
        JSON.parse(model?.content_json)
      );
    }
  };

  const onReadyEdtior = () => {
    // editor is ready
    //console.log("onReady");
    setEditorIsLoading(false);
    emailEditorRef.current?.editor?.setMergeTags(mergetags?.data.data);
  };
  return (
    <div className="relative">
      {editorIsLoading && (
        <div className="text-center w-full h-full absolute flex items-center bg-white bg-opacity-70 z-10">
          <div className="w-full">
            <Loader type="ball-move" className="mx-auto mb-4" />
            <span className="mx-auto">Chargement de l'éditeur...</span>
          </div>
        </div>
      )}
      <LoaderBarWrapper loading={isLoading}>
        <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)}>
          <Card
            title={"Modification d'un model"}
            extras={[<SubmitButton className="ml-auto" key={"submit"} />]}
          >
            <div className="w-1/4 text-lg mb-4">
              ModelId:{model.uuid}
            </div>
            <div className={"grid grid-cols-2 gap-4 mb-4"}>
              <div>
                <InputText
                  placeholder={"Entrer l'intitulé du template"}
                  label={"Intitulé"}
                  register={register}
                  error={errors?.name}
                  name={"name"}
                  defaultValue={model?.name}
                />
              </div>
              <div>
                <InputText
                  placeholder={"Entrer la description du template"}
                  label={"Description"}
                  register={register}
                  error={errors?.description}
                  name={"description"}
                  defaultValue={model?.description}
                />
              </div>
            </div>
            <Suspense>
              <Label name={""} text={"Contenu"} />
              <EmailEditor
                editorId="editor"
                style={{
                  height: "100vh",
                  border: "1px solid lightgray",
                  marginTop: "5px",
                }}
                locale={i18n.language}
                ref={emailEditorRef}
                onLoad={onLoadEditor}
                onReady={onReadyEdtior}
              />
            </Suspense>
          </Card>
        </Form>
      </LoaderBarWrapper>
    </div>
  );
}
