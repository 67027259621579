import { BiCollection, BiCopyAlt, BiData, BiHome, BiLandscape, BiMailSend, BiPencil } from "react-icons/bi";
import { UserRoles } from "./global"
import { MdContactPhone } from "react-icons/md";

export interface MenuInterface {
    icon: React.FC;
    title: string;
    link: string;
    pathname: string;
    roles?: UserRoles[];
    subMenus?: MenuInterface[];
  }
  
export const MENUS: MenuInterface[] = [
    {
        icon: BiHome,
        title: "Dashboard",
        link: "/dashboard",
        pathname: "dashboard",
      },
    {
        icon: BiCopyAlt,
        title: "Modèles",
        link: "/models",
        pathname: "models"
    },
    {
        icon: BiMailSend,
        title: "Campagnes",
        link: "/campaigns",
        pathname: "campaigns"
    },
    {
        icon: BiCollection,
        title: "Groupes",
        link: "/groups",
        pathname: "groups"
    },
    {
        icon: MdContactPhone,
        title: "Contacts",
        link: "/contacts",
        pathname: "contacts"
    },
    {
        icon: BiLandscape,
        title: "Templates",
        link: "/templates",
        pathname: "templates",
        roles: [UserRoles.NEXAH]
    },
    {
        icon: BiData,
        title:"Recharges",
        link: "/recharges",
        pathname: "recharges"
    },
    {
        icon: BiData,
        title:"Transactions",
        link: "/transactions",
        pathname: "transactions"
    },
    {
        icon: BiPencil,
        title:"Paramètres",
        link: "/settings",
        pathname: "settings"
    }
]
