import moment from "moment";
import CardTable from "../../components/card/CardTable";
import { ColumnInterface } from "../../components/table/typings";
import { useQueries } from "../../hooks/useQueries";
import { APP_API_URL } from "../../config/api";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { AddButton } from "../../components/button/CustomButton";
import { useNavigate } from "react-router-dom";
import { useMutateWithInvalidateQueries } from "../../hooks/useMutations";
import { HTTPStatusResponse, swal } from "../../config/global";
import { toast } from "react-toastify";
import { BiTrash } from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im";
import SimpleButton from "../../components/button/SimpleButton";
import { BadgeRounded } from "../../components/badge/Badge";

export default function TemplatePage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    pagination,
    setPagination,
    currentElement,
    setCurrentElement,
  } = useHeaderFunctionPage({ baseApiUrl: "template", title: "Templates" });
  const navigate = useNavigate();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  /**
   * Mutations
   */

  const { mutateAsync: mutateDeleteData, isLoading: deleteIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "delete",
      queryKey
    );

  /**
   * Functions
   */
  const hanldeAddElement = () => {
    navigate("/templates/add");
  };

  const handleSubmitDeleteForm = (element: any) => {
    setCurrentElement(element.id);
    swal
      .fire({
        text: "Confirmer la suppression du templates!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          mutateDeleteData({}).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success("Le template a été supprimé!");
            }
          });
        }
      });
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Aperçu",
      key: "preview",
      render: (element: any) => {
        return (
          <iframe
            srcDoc={element.content_html}
            height={100}
            width={300}
            scrolling={"no"}
            className="mx-auto"
            title={element.name}
          ></iframe>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Etiquettes",
      key: "tag",
      render: (element:any) => element.tag_names.split(",").map((tag:any) => <BadgeRounded text={tag} className="bg-secondary mx-1"/>)
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          {deleteIsLoading && currentElement === element.id ? (
            <ImSpinner2 className="animate-spin text-red-500" size={"24px"} />
          ) : (
            <>
              <SimpleButton
                className="text-red-500 hover:bg-red-500 hover:text-white transition-all rounded"
                title="Supprimer"
                onClick={() => handleSubmitDeleteForm(element)}
              >
                <BiTrash />
              </SimpleButton>
            </>
          )}
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton key="add" value={"Créer"} onClick={hanldeAddElement} />,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
    </>
  );
}
