import moment from "moment";
import { AddButton } from "../../components/button/CustomButton";
import CardTable from "../../components/card/CardTable";
import { DATE_TIME_FORMAT } from "../../config/global";
import { ColumnInterface } from "../../components/table/typings";
import { useQueries } from "../../hooks/useQueries";
import { APP_API_URL } from "../../config/api";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { BadgeRounded } from "../../components/badge/Badge";
import Drawer from "../../components/layout/Drawer";
import { Link } from "react-router-dom";
import OneModel from "../model/OneModel";
import SimpleButton from "../../components/button/SimpleButton";

export default function CampaignPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "campaign", title: "Campagnes" });

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  const {
    data: models,
    refetch: refetchModel,
    isLoading: modelIsLoading,
  } = useQueries(APP_API_URL + `models?paginate=0`, ["models-list"], {
    enabled: false,
  });

  /**
   * Functions
   */

  const handleOpenDrawer = () => {
    setOpenModal(true);
    refetchModel();
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Nom",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Sujet",
      key: "subject",
      dataIndex: "subject",
    },
    {
      title: "Email destinateur",
      key: "from_email",
      dataIndex: "from_email",
    },
    {
      title: "Nom destinateur",
      key: "from_name",
      dataIndex: "from_name",
    },
    {
      title: "Status",
      key: "status",
      render: (element: any) => (
        <BadgeRounded className={element.status === "completed"?"bg-soft-success":"bg-soft-warning"} text={element.status} />
      ),
    },
    {
      title: "Stats",
      key: "stats",
      render: (element: any) => (
        <div>
          <BadgeRounded
            className="bg-secondary h-5 md:mr-2"
            text={`Contact : ${element.nb_contact}`}
          />
          <BadgeRounded
            className="bg-primary h-5 md:mr-2"
            text={`Envoyé : ${element.sent}`}
          />
          <BadgeRounded
            className="bg-success h-5 md:mr-2"
            text={`Délivré : ${element.delivered}`}
          />
          <BadgeRounded
            className="bg-success h-5 md:mr-2"
            text={`Echoué : ${element.failed}`}
          />
          <BadgeRounded
            className="bg-soft-secondary h-5"
            text={`Mails ouverts : ${element.opened}`}
          />
        </div>
      ),
    },
    {
      title: "Programmé le",
      key: "schedule_time",
      render: (element: any) => (
        <>
          {element.schedule_time
            ? moment(element.schedule_time).format(DATE_TIME_FORMAT)
            : ""}
        </>
      ),
    },
    {
      title: "Crée le",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format(DATE_TIME_FORMAT)}</>
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          <Link
            to={`/campaigns/mails/${element.id}`}
            state={{
              campaign: element,
            }}
          >
            <SimpleButton
              className={"bg-soft-secondary rounded"}
              onClick={() => {}}
            >
              Détails
            </SimpleButton>
          </Link>
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton
            key={"add"}
            onClick={handleOpenDrawer}
            value={"Démarrer"}
          />,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <Drawer
        open={openModal}
        title={`Choisir un modèle pour démarrer une campagne`}
        isLoading={modelIsLoading}
        setOpen={() => setOpenModal(false)}
      >
        <div className="grid grid-cols-2 gap-4">
          {models?.data.data.map((model: any) => {
            return (
              <Link key={model.id}
                to={`/campaigns/create`}
                state={{
                  model: model.id,
                }}
              >
                <div className="relative hover:cursor-pointer border border-gray-300">
                  <OneModel
                    name={model.name}
                    content={model.content_html}
                    height={150}
                  />
                  <div className="inset-0 absolute hover:bg-white hover:bg-opacity-30 transition-colors"></div>
                </div>
                <h1 className="text-center text-md mt-2 w-full block">
                  {model.name}
                </h1>
              </Link>
            );
          })}
        </div>
      </Drawer>
    </>
  );
}
