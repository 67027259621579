import { useQueries } from "../../hooks/useQueries";
import { APP_API_URL } from "../../config/api";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { Link, useNavigate } from "react-router-dom";
import { BiDotsVerticalRounded, BiPlus } from "react-icons/bi";
import OneModel from "./OneModel";
import Dropdown from "../../components/dropdown/Dropdown";
import { ItemDropdownInterface } from "../../components/dropdown/typings";
import { HTTPStatusResponse, swal } from "../../config/global";
import { useMutateWithInvalidateQueries } from "../../hooks/useMutations";
import { ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";
import ModelSkeleton from "./ModelSkeleton";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import SimpleButton from "../../components/button/SimpleButton";
import newTemplateSvg from "../../assets/images/new-template.svg";

export default function ModelPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    queryKey,
    pagination,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
  } = useHeaderFunctionPage({
    baseApiUrl: "model",
    title: "Modèles",
  });
  const navigate = useNavigate();
  const [currentTag, setCurrentTag] = useState("all");
  const [templatesDatas, setTemplatesDatas] = useState<any>([]);

  /**
   * Query
   */
  const { data: models, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  const { data: tags, refetch: refetchTags } = useQueries(
    APP_API_URL + `tags?paginate=0`,
    ["tags-list"],
    {
      enabled: false,
    }
  );

  const { data: templates, refetch: refetchTemplates } = useQueries(
    APP_API_URL + `templates?paginate=0`,
    ["templates-list"],
    {
      enabled: false,
      onSuccess: (data: any) => loadTemplates(data?.data?.data, "all"),
    }
  );

  /**
   * Mutation
   */

  const { mutateAsync: mutateDeleteData, isLoading: deleteIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "delete",
      queryKey
    );

  /**
   * Functions
   */
  const handleDeleteModel = (model: any) => {
    setCurrentElement(model.id);
    swal
      .fire({
        text: "Confirmer la suppression du model!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          mutateDeleteData({}).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success("Le groupe a été supprimé!");
            }
          });
        }
      });
  };

  const handleSelectTag = (tag: string) => {
    loadTemplates(templates?.data?.data, tag);
  };

  const loadTemplates = (datas: any, tag: string) => {
    const newTemplates = datas.filter((data: any) =>
      data.tag_names.includes(tag)
    );
    setCurrentTag(tag);
    setTemplatesDatas(tag === "all" ? datas : newTemplates);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTemplatesDatas(templates?.data.data);
    setCurrentTag("all");
  };

  /**
   * Datas
   */

  const modelItems: ItemDropdownInterface[] = [
    {
      onClick: (data: any) =>
        navigate("/campaigns/create", {
          state: { model: data.id },
        }),
      children: () => <span className="ml-2">Démarrer une campagne</span>,
    },
    {
      onClick: (data: any) =>
        navigate(`/models/edit/${data.id}`, {
          state: {
            model: data,
          },
        }),
      children: () => <span className="ml-2">Modifier</span>,
    },
    {
      onClick: (data: any) => handleDeleteModel(data),
      children: () => <span className="ml-2">Supprimer</span>,
    },
  ];
  /**
   * Effect
   */
  useEffect(() => {
    refetchTags();
    refetchTemplates();
  }, []);

  /**
   * UI
   */
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4">
        <div>
          <SimpleButton
            style={{ height: "250px" }}
            className="w-full"
            onClick={() => setOpenModal(true)}
          >
            <div
              className="p-10 bg-white bg-opacity-60 wave-effect flex items-center h-full bg-no-repeat bg-contain"
              style={{
                backgroundImage: `url(${newTemplateSvg})`,
                backgroundPosition: "bottom",
              }}
            >
              <BiPlus className="mx-auto my-auto" size={40} />
            </div>
          </SimpleButton>
          <h1 className="text-center text-lg mt-4">Créer un modèle</h1>
        </div>
        {models?.data.data.map((model: any, index: number) => {
          return (
            <div key={index}>
              <div className="relative">
                <OneModel name={model.name} content={model.content_html} />
                <div className="inset-0 absolute hover:bg-white hover:bg-opacity-30 transition-colors"></div>
              </div>
              <div className="flex justify-between items-center bg-white px-2">
                <span className="text-lg">{model.name}</span>
                {deleteIsLoading && currentElement === model.id ? (
                  <div className="py-4">
                    <ImSpinner2
                      className="animate-spin text-red-500"
                      size={"24px"}
                    />
                  </div>
                ) : (
                  <Dropdown
                    dataToItem={model}
                    type="dropup"
                    buttonClassName={"header-item noti-icon"}
                    buttonChildren={
                      <BiDotsVerticalRounded
                        size={16}
                        className="fill-neutral-800"
                      />
                    }
                    items={modelItems}
                  />
                )}
              </div>
            </div>
          );
        })}
        {isLoading &&
          [1, 2, 3, 4].map((value: number) => {
            return <ModelSkeleton key={value} />;
          })}
      </div>

      <Transition appear show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-[99999]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 pointer-events-none" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform overflow-hidden bg-white text-left shadow-xl transition-all">
                  <div className="flex items-center justify-between py-2 px-4 border-b border-solid border-slate-200 rounded-t">
                    Choisir un template
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={handleCloseModal}
                    >
                      <span className="bg-transparent text-red-500 h-6 w-6 text-2xl block">
                        <i className="bx bx-x"></i>
                      </span>
                    </button>
                  </div>
                  <div className="px-4 py-4 ">
                    <div className="inline-flex mb-4">
                      <SimpleButton
                        className={`${
                          currentTag === "all"
                            ? "bg-blue-600 text-white"
                            : "bg-soft-primary"
                        } rounded mx-2 w-48 md:w-auto`}
                        onClick={() => handleSelectTag("all")}
                      >
                        Tous
                      </SimpleButton>
                      <div className="inline-flex overflow-x-auto w-full">
                        {tags?.data.data.map((tag: any, index: number) => {
                          return (
                            <SimpleButton
                              key={`tag-${index}`}
                              className={`${
                                tag.name === currentTag
                                  ? "bg-blue-600 text-white"
                                  : "bg-soft-primary"
                              } rounded mx-2 min-w-max`}
                              onClick={() => handleSelectTag(tag.name)}
                            >
                              {tag.name}
                            </SimpleButton>
                          );
                        })}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-4">
                      <Link to={"/models/add"}>
                        <div className="bg-white shadow borderborder-gray-200 rounded">
                          <div className="bg-light relative">
                            <img
                              src={newTemplateSvg}
                              alt="New Template"
                              className="h-40 aspect-auto rounded-t"
                            />
                            <div className="inset-0 absolute hover:bg-white hover:bg-opacity-30 transition-colors"></div>
                          </div>
                          <p className="p-5">
                            <b className="block font-extrabold">
                              Template vièrge
                            </b>
                            <small>Créer à partir d'un template vierge</small>
                          </p>
                        </div>
                      </Link>
                      {templatesDatas?.map((template: any) => {
                        return (
                          <Link
                            to={`/models/add/${template.id}`}
                            key={template.id}
                          >
                            <div className="bg-white shadow borderborder-gray-200 rounded">
                              <div className="relative">
                                <iframe
                                  srcDoc={template.content_html}
                                  width={"100%"}
                                  scrolling={"no"}
                                  className="mx-auto"
                                  title={template.name}
                                  style={{
                                    height: "10rem",
                                  }}
                                ></iframe>
                                <div className="inset-0 absolute hover:bg-white hover:bg-opacity-30 transition-colors"></div>
                              </div>
                              <p className="p-5">
                                <b className="block font-extrabold">
                                  {template.name}
                                </b>
                                <small>{template.description}</small>
                              </p>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
