import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AddButton,
  EditButton,
  LinkButton,
} from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import ModalForm from "../components/modal/ModalForm";
import { HTTPStatusResponse, FormActionType, swal } from "../config/global";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import { InputEmail, InputText } from "../components/form/Input";
import SimpleButton from "../components/button/SimpleButton";
import { BiCloudDownload, BiTrash } from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im";
import useFileUpload from "../components/form/useFileUpload";
import { useState } from "react";

export default function ContactPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    actionForm,
    setActionForm,
    titleForm,
    setTitleForm,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "contact", title: "Contact" });
  const [openImportModal, setOpenImportModal] = useState(false);

  const {
    file,
    FileUploadView,
    reset: resetFileUpload,
    reponseUploadData,
  } = useFileUpload({
    externalUpload: true,
    url: APP_API_URL + "contact-upload",
  });

  /**
   * Form
   */
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { handleSubmit: handleSubmitImport } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: mutateImportData, isLoading: importDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-import`,
      "post",
      queryKey,
      {
        "Content-Type": "multipart/form-data",
      }
    );

  const { mutateAsync: updateData, isLoading: updateIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "put",
      queryKey
    );

  const { mutateAsync: mutateDeleteData, isLoading: deleteIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "delete",
      queryKey
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    storeData(values).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        toast.success("Contact ajouté !");
        handleCancelForm();
      } else {
        if (response?.data?.status === HTTPStatusResponse.ERROR) {
          toast.error(response.data.message);
        }
      }
    });
  };

  const handleSubmitImportForm = () => {
    mutateImportData({ file: file }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        toast.success("Contact importé!");
        handleCancelForm();
      }
    });
  };
  const handleSubmitEditForm = (values: any) => {
    updateData(values).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        toast.success("Contact modifié");
        handleCancelForm();
      }
    });
  };

  const handleSubmitDeleteForm = (element: any) => {
    setCurrentElement(element.id);
    swal
      .fire({
        text: "Confirmer la suppression du contact!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          mutateDeleteData({}).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success("Le contact a été supprimé!");
            }
          });
        }
      });
  };

  const handleCancelForm = () => {
    reset();
    resetFileUpload();
    setOpenModal(false);
    setOpenImportModal(false);
    setCurrentElement(0);
  };

  const handleAddElement = () => {
    setTitleForm("Ajouter un contact");
    setActionForm(FormActionType.ADD);
    setOpenModal(true);
    reset();
  };

  const handleImportElement = () => {
    setTitleForm("Impoter des contacts");
    setOpenImportModal(true);
    resetFileUpload();
  };

  const handleEditElement = (element: any) => {
    setTitleForm("Modifier un contact");
    setActionForm(FormActionType.EDIT);
    setValue("email", element.email);
    setValue("first_name", element.first_name);
    setValue("last_name", element.last_name);
    setCurrentElement(element.id);
    setOpenModal(true);
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Emails",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Noms",
      key: "first_name",
      dataIndex: "first_name",
    },
    {
      title: "Prénoms",
      key: "last_name",
      dataIndex: "last_name",
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          {deleteIsLoading && currentElement === element.id ? (
            <ImSpinner2 className="animate-spin text-red-500" size={"24px"} />
          ) : (
            <>
              <EditButton
                value={""}
                onClick={() => handleEditElement(element)}
              />
              <SimpleButton
                className="text-red-500 hover:bg-red-500 hover:text-white transition-all rounded"
                title="Supprimer"
                onClick={() => handleSubmitDeleteForm(element)}
              >
                <BiTrash />
              </SimpleButton>
            </>
          )}
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton
            key={"add"}
            onClick={handleAddElement}
            value={"Ajouter"}
          />,
          <LinkButton
            key={"import"}
            onClick={handleImportElement}
            className="flex items-center gap-2 rounded-md"
          >
            <BiCloudDownload /> Importer
          </LinkButton>,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={
          actionForm === FormActionType.ADD
            ? storeDataIsLoading
            : updateIsLoading
        }
        onClose={handleCancelForm}
        onConfirm={handleSubmit(
          actionForm === FormActionType.ADD
            ? handleSubmitAddForm
            : handleSubmitEditForm
        )}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <InputEmail
              placeholder={"Entrer l'email"}
              label={"Email"}
              register={register}
              error={errors?.email}
              name={"email"}
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer le nom"}
              label={"Nom"}
              register={register}
              error={errors?.first_name}
              name={"first_name"}
              required={false}
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer le prénom"}
              label={"Prénom"}
              register={register}
              error={errors?.last_name}
              name={"last_name"}
              required={false}
            />
          </div>
        </div>
      </ModalForm>
      <ModalForm
        title={titleForm}
        isLoading={importDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmitImport(handleSubmitImportForm)}
        open={openImportModal}
      >
        {reponseUploadData && reponseUploadData?.data && (
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="bg-soft-secondary px-2 py-3 text-center">
              <span className="text-xl block">
                {reponseUploadData.data.total}
              </span>
              <span>Total de Contacts</span>
            </div>
            <div className="bg-soft-success px-2 py-3 text-center">
              <span className="text-xl block">
                {reponseUploadData.data.valid_email_count}
              </span>
              <span>Contacts valides</span>
            </div>
            <div className="bg-soft-danger px-2 py-3 text-center">
              <span className="text-xl block">
                {reponseUploadData.data.invalid_email_count}
              </span>
              <span>Contacts invalides</span>
            </div>
          </div>
        )}
        <FileUploadView
          accept={
            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          name={"file"}
        />
      </ModalForm>
    </>
  );
}
