import moment from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputNumber } from "../components/form/Input";
import { Select2 } from "../components/form/Select";
import ModalForm from "../components/modal/ModalForm";
import { ColumnInterface } from "../components/table/typings";
import { APP_API_URL } from "../config/api";
import { HTTPStatusResponse, DATE_TIME_FORMAT } from "../config/global";
import useHeaderFunctionPageOne from "../hooks/useHeaderFunctionPageOne";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import { useQueries } from "../hooks/useQueries";

export default function SettingPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    titleForm,
    setTitleForm,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPageOne({ baseApiUrl: "orgsetting", title: "Api Key" });
  /**
   * Form
   */
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      ...values,
      org_id: values.org_id.id,
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        toast.success("Recharge éffectuée !");
        handleCancelForm();
      }
    });
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Effectuer une recharge");
    setOpenModal(true);
    reset();
  };


  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title:datas?.data.data.api_key,
      key: "",
      dataIndex: "",
    }
  ];
  console.log(datas?.data.data.api_key)
  /**
   * UI
   */
  return (
    <>
    <div className="grid grid-cols-2 gap-12">
      <CardTable
        extras={[]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
    </div>
    </>
  );
}
