export default function ModelSkeleton() {
  return (
    <div>
      <div
        className="bg-soft-secondary animate-pulse w-full"
        style={{ height: "250px" }}
      ></div>
      <div className="flex justify-between items-center mt-4">
        <div className="w-3/4">
          <div className="h-2 bg-soft-secondary animate-pulse w-2/3"></div>
          <div className="mt-1 h-2 bg-soft-secondary animate-pulse w-1/3"></div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="w-1 h-1 rounded-full bg-soft-secondary animate-pulse"></div>
          <div className="w-1 h-1 rounded-full bg-soft-secondary animate-pulse"></div>
          <div className="w-1 h-1 rounded-full bg-soft-secondary animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}
