import moment from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputNumber } from "../components/form/Input";
import { Select2 } from "../components/form/Select";
import ModalForm from "../components/modal/ModalForm";
import { ColumnInterface } from "../components/table/typings";
import { APP_API_URL } from "../config/api";
import { HTTPStatusResponse, DATE_TIME_FORMAT } from "../config/global";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import { useQueries } from "../hooks/useQueries";
import { BadgeCircleCheck, BadgeRounded } from "../components/badge/Badge";


export default function TransactionPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    titleForm,
    setTitleForm,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "transaction", title: "Transactions" });

  /**
   * Form
   */
  const {
    reset,
    register,                                                                               
    handleSubmit,                                                                                                                                                                          
    control,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(                                                                                    
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );                                                                                                  

  const { data: organizations, refetch: refecthOrganization } = useQueries(
    APP_API_URL + `orgsettings?paginate=0`,
    ["orgsettings-list"],
    {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      ...values,
      org_id: values.org_id.id,
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        toast.success("Recharge éffectuée !");
        handleCancelForm();
      }
    });
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Effectuer une recharge");
    setOpenModal(true);
    reset();
  };

  /**
   * Effect
   */
  useEffect(() => {
    refecthOrganization();
  }, [refecthOrganization]);

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Expediteur",
      key: "from",
      dataIndex: "from",
    },
    {
      title: "Destinataire",
      key: "to",
      dataIndex: "to",
    },
    {
      title: "Sujet",
      key: "subject",
      dataIndex: "subject",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (element: any) => {
        if (element.status === "sent") {
          return (
            <BadgeRounded className="bg-soft-secondary" text={element.status} />
          );
        } else if (
          element.status === "delivered" ||
          element.status === "spam"
        ) {
          return (
            <BadgeRounded className="bg-soft-success" text={element.status} />
          );
        } else {
          return (
            <BadgeRounded className="bg-soft-danger" text={element.status} />
          );
        }
      },
    },
    {
      title: "Mail ouvert",
      key: "sent_at",
      render: (element: any) => (
        <>
          <BadgeCircleCheck active={element.is_open} />
        </>
      ),
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format(DATE_TIME_FORMAT)}</>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
    </>
  );
}
