import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { BiCloudDownload, BiTrash } from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im";
import { AddButton, LinkButton } from "../../components/button/CustomButton";
import SimpleButton from "../../components/button/SimpleButton";
import CardTable from "../../components/card/CardTable";
import ModalForm from "../../components/modal/ModalForm";
import { ColumnInterface } from "../../components/table/typings";
import { APP_API_URL } from "../../config/api";
import { HTTPStatusResponse, swal, FormActionType } from "../../config/global";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { useMutateWithInvalidateQueries } from "../../hooks/useMutations";
import { useQueries } from "../../hooks/useQueries";
import { useLocation } from "react-router-dom";
import { Select2 } from "../../components/form/Select";
import { useEffect, useState } from "react";
import useFileUpload from "../../components/form/useFileUpload";

export default function GroupContactPage() {
  /**
   * Hooks
   */
  const { state } = useLocation();
  const group = state?.group;
  const {
    title,
    queryKey,
    setActionForm,
    titleForm,
    setTitleForm,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({
    baseApiUrl: "contact-group",
    title: `Contact du groupe ${group?.name}`,
  });

  const {
    file,
    FileUploadView,
    reset: resetFileUpload,
    reponseUploadData,
  } = useFileUpload({
    externalUpload: true,
    url: APP_API_URL + "contact-upload",
  });
  const [openImportModal, setOpenImportModal] = useState(false);

  /**
   * Form
   */
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { handleSubmit: handleSubmitImport } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `contacts/${group?.id}?page=${pagination.page}&perPage=${pagination.perPage}`,
    ["group-contacts", group?.id]
  );

  const { data: contacts, refetch: refetchContact } = useQueries(
    APP_API_URL + `contacts-out/${group?.id}`,
    ["contacts-list"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `contact-store/${group?.id}`,
      "post",
      queryKey
    );

  const { mutateAsync: mutateDeleteData, isLoading: deleteIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `contact/${currentElement}/${group?.id}`,
      "delete",
      queryKey
    );

  const { mutateAsync: mutateImportData, isLoading: importDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `contact-import/${group?.id}`,
      "post",
      queryKey,
      {
        "Content-Type": "multipart/form-data",
      }
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      contacts: values.contacts.map((contact: any) => contact.id),
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        toast.success("Contacts ajoutés !");
        handleCancelForm();
        refetchContact();
      }
    });
  };

  const handleSubmitImportForm = () => {
    mutateImportData({ file: file }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        toast.success("Contact importé!");
        handleCancelForm();
      }
    });
  };

  const handleSubmitDeleteForm = (element: any) => {
    setCurrentElement(element.id);
    swal
      .fire({
        text: "Confirmer la suppression du contact dans le groupe!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          mutateDeleteData({}).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success("Le contact a retiré du groupe!");
              refetchContact();
            }
          });
        }
      });
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
    setCurrentElement(0);
    resetFileUpload();
    setOpenImportModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Ajouter des contacts");
    setActionForm(FormActionType.ADD);
    setOpenModal(true);
    reset();
  };

  const handleImportElement = () => {
    setTitleForm("Impoter des contacts");
    setOpenImportModal(true);
    resetFileUpload();
  };

  /**
   * Effect
   */
  useEffect(() => {
    refetchContact();
  }, [refetchContact]);

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "ID",
      key: "id",
      render: (element, index) => <>{index + 1}</>,
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Name",
      key: "name",
      render: (element: any) => (
        <span>{element.first_name + " " + element.last_name}</span>
      ),
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          {deleteIsLoading && currentElement === element.id ? (
            <ImSpinner2 className="animate-spin text-red-500" size={"24px"} />
          ) : (
            <>
              <SimpleButton
                className="text-red-500 hover:bg-red-500 hover:text-white transition-all rounded"
                title="Supprimer"
                onClick={() => handleSubmitDeleteForm(element)}
              >
                <BiTrash />
              </SimpleButton>
            </>
          )}
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton
            key={"add"}
            onClick={handleAddElement}
            value={"Ajouter"}
          />,
          <LinkButton
            key={"import"}
            onClick={handleImportElement}
            className="flex items-center gap-2 rounded-md"
          >
            <BiCloudDownload /> Importer
          </LinkButton>,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <Select2
              multiple
              control={control}
              placeholder={"Selection des contacts"}
              label={"Type de SMS"}
              register={register}
              error={errors?.contacts}
              name={"contacts"}
              textKeyItem={"email"}
              valueKeyItem={"id"}
              items={contacts?.data?.data}
            />
          </div>
        </div>
      </ModalForm>

      <ModalForm
        title={titleForm}
        isLoading={importDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmitImport(handleSubmitImportForm)}
        open={openImportModal}
      >
        {reponseUploadData && reponseUploadData?.data && (
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="bg-soft-secondary px-2 py-3 text-center">
              <span className="text-xl block">
                {reponseUploadData.data.total}
              </span>
              <span>Total de Contacts</span>
            </div>
            <div className="bg-soft-success px-2 py-3 text-center">
              <span className="text-xl block">
                {reponseUploadData.data.valid_email_count}
              </span>
              <span>Contacts valides</span>
            </div>
            <div className="bg-soft-danger px-2 py-3 text-center">
              <span className="text-xl block">
                {reponseUploadData.data.invalid_email_count}
              </span>
              <span>Contacts invalides</span>
            </div>
          </div>
        )}
        <FileUploadView
          accept={
            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          name={"file"}
        />
      </ModalForm>
    </>
  );
}
