export default function OnModel({ name, content, height = "250" }: { name: string, content: string, height?: string|number }) {
  return (
    <iframe
      loading={"lazy"}
      src="about:blank"
      title={name}
      height={height}
      srcDoc={content}
      style={{
        width: "100%",
      }}
      scrolling="no"
    />
  );
}
